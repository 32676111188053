// @ts-nocheck
import styled from 'styled-components'
import { useState,useEffect } from 'react'
import { NextLinkFromReactRouter } from 'components/NextLink'
import { Flex, Text, TicketFillIcon, PredictionsIcon, Button, ArrowForwardIcon, Row, Box, Alert } from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import useTheme from 'hooks/useTheme'
import Red from "./resources/images/red-heart.png";
import Blue from "./resources/images/blue-heart.jpeg";
import Green from "./resources/images/green-heart.jpeg";
import ColoredWordHeading from '../ColoredWordHeading'
import IconCard, { IconCardData } from '../IconCard'
import PredictionCardContent from './PredictionCardContent'
import PredictionCardContent2 from './PredictionCardContent2'
import PredictionCardContent3 from './PredictionCardContent3'
import LotteryCardContent from './LotteryCardContent'
import CompositeImage from '../CompositeImage'
import { useWallet, useConnection } from '@solana/wallet-adapter-react';
import axios from "axios";
import { signAndConfirm, signAndConfirmTransaction,signAndConfirmBoth } from "./utility/common";
import { ReactSession } from "react-client-session";




const StyledLink = styled(NextLinkFromReactRouter)`
  width: 100%;
`

const TransparentFrame = styled.div<{ isDark: boolean }>`
  background: ${({ theme }) => (theme.isDark ? 'rgba(8, 6, 11, 0.6)' : ' rgba(255, 255, 255, 0.6)')};
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.colors.cardBorder};
  box-sizing: border-box;
  backdrop-filter: blur(12px);
  border-radius: 72px;

  ${({ theme }) => theme.mediaQueries.md} {
    padding: 40px;
  }
`

const BgWrapper = styled.div`
  z-index: -1;
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
`

const BottomLeftImgWrapper = styled(Flex)`
  position: absolute;
  left: 0;
  bottom: -64px;
  max-width: 192px;

  ${({ theme }) => theme.mediaQueries.md} {
    max-width: 100%;
  }
`

const TopRightImgWrapper = styled(Flex)`
  position: absolute;
  right: 0;
  top: -64px;

  max-width: 192px;

  ${({ theme }) => theme.mediaQueries.md} {
    max-width: 100%;
  }
`





const PredictionCardData: IconCardData = {
  icon: <PredictionsIcon width="36px" color="inverseContrast" />,
  background: ' linear-gradient(180deg, #7645D9 0%, #5121B1 100%);',
  borderColor: '#313638',
  rotation: '-2.36deg',
}


const PredictionCardData2: IconCardData = {
  icon: <PredictionsIcon width="36px" color="inverseContrast" />,
  background: 'linear-gradient(180deg, #EF6461 0%, #EA8C62 51.17%, #E7A063 100%);',
  borderColor: '#313638',
  rotation: '-2.36deg',
}




const PredictionCardData3: IconCardData = {
  icon: <PredictionsIcon width="36px" color="inverseContrast" />,
  background: 'linear-gradient(180deg, #ffb237 0%, #ffcd51 51.17%, #ffe76a 100%);',
  borderColor: '#ffb237',
  rotation: '-2.36deg',
}











const LotteryCardData: IconCardData = {
  icon: <TicketFillIcon color="white" width="36px" />,
  background: ' linear-gradient(180deg, #7645D9 0%, #5121B1 100%);',
  borderColor: '#3C1786',
  rotation: '1.43deg',
}

const bottomLeftImage = {
  path: '/images/home/prediction-cards/',
  attributes: [
    { src: 'bottom-left', alt: 'CAKE card' },
    { src: 'green', alt: 'Green CAKE card with up arrow' },
    { src: 'red', alt: 'Red Cake card with down arrow' },
    { src: 'top-right', alt: 'CAKE card' },
  ],
}

const topRightImage = {
  path: '/images/home/lottery-balls/',
  attributes: [
    { src: '2', alt: 'Lottery ball number 2' },
    { src: '4', alt: 'Lottery ball number 4' },
    { src: '6', alt: 'Lottery ball number 6' },
    { src: '7', alt: 'Lottery ball number 7' },
    { src: '9', alt: 'Lottery ball number 9' },
  ],
}

const WinSection = () => {
  const { t } = useTranslation()
  const { theme } = useTheme()



  const [isValid, setIsValid] = useState(false)
  const [isTx,setIsTx]=useState(false)
  let walletId = ""
  const wallet = useWallet();
  console.log('here is the wallet')
  console.log(wallet)
  const { connection } = useConnection();
  useEffect(() => {
    if (wallet.publicKey !== null) {
      setIsValid(true)
      console.log('as you can see the lesson is not coming together')
      console.log(wallet.publicKey.toBase58())
      walletId=wallet.publicKey.toBase58()
      console.log(walletId)

    }
    if (wallet.publicKey === null) {
      setIsValid(false)
    }


  }, [wallet.publicKey, connection])

  const callback = (signature, result) => {
    console.log("Signature ", signature);
    console.log("result ", result);
  };

  const callback2 = (signature, result) => {
    console.log("Sigining Transaction");
    console.log("Signature ", signature);
    console.log("result ", result);
    console.log("In callback 2");
    ReactSession.set("subs_name", "");
    ReactSession.set("subs_addr", null);
    ReactSession.set("subs_expr", "");
    ReactSession.set("subs_discount", "");
    ReactSession.set("subs_actv", false);

  };






  const renewMembership = (value) => {

    if (wallet.publicKey!==null){




        // console.log(new Date(new Date().setMonth(new Date().getMonth() + 3)).toISOString().slice(0, 10));
        const memToken = ReactSession.get("subs_addr") ?? null;
        console.log(memToken);

        // var green_file;

        // const readFiles = async () => {
        //   const readFileGreen = await BinaryFile.open('path-to-file');
        // }
        var formData = new FormData();
        if (memToken) {

          //deduct api call here
        const xKey2 = "G6gjDRS12g4DkNBu";
        const endPoint2 = "https://api.shyft.to/sol/v1/";
        var amount_to_be_deducted = 0;
        if (value === "green") {
          amount_to_be_deducted = 0.5;
        } else if (value === "blue") {
          amount_to_be_deducted = 1;
        } else {
          amount_to_be_deducted = 1.2;
        }
        const publicKey = "5dYcbon4BkjSZ8ivGiNqBYdFK97GJSAhV4wttx9f2pPB"; //marketplace owner wallet

        let nftUrl2 = `${endPoint2}wallet/send_sol_detach`;
        axios({
          // Endpoint to get NFTs
          url: nftUrl2,
          method: "POST",
          headers: {
            "x-api-key": xKey2,
          },
          data: {
            network: "mainnet-beta",
            from_address: walletId,
            to_address: publicKey,
            amount: amount_to_be_deducted,
          },
        })
          // Handle the response from backend here
          .then(async (res) => {
            console.log(res)
            //console.log(res.data);
            console.log("NFTs: ");
            if (res.data.success === true) {
              const transaction = res.data.result.encoded_transaction;
              const ret_result = await signAndConfirmTransaction(
                "devnet",
                transaction,
                callback
              ); //flow from here goes to utility func
              console.log(ret_result);
            } else {
              console.log("failed");
            }
          })
          // Catch errors if any
          .catch((err) => {
            console.warn(err);
          });



          if (value === "green") {
            var todayDate = new Date().toISOString().slice(0, 10);
            var expDate = new Date(new Date().setMonth(new Date().getMonth() + 3))
              .toISOString()
              .slice(0, 10);
            formData.append("name", "COPE PASS");
            formData.append("file", new File(["redheart"],Green));
            formData.append(
              "attributes",
              JSON.stringify([
                {
                  trait_type: "name",
                  value: "Green Tier",
                },
                {
                  trait_type: "level",
                  value: "1",
                },
                {
                  trait_type: "start_date",
                  value: todayDate,
                },
                {
                  trait_type: "exp_date",
                  value: expDate,
                },
              ])
            );
          } else if (value === "blue") {
            var todayDate = new Date().toISOString().slice(0, 10);
            var expDate = new Date(new Date().setMonth(new Date().getMonth() + 6))
              .toISOString()
              .slice(0, 10);
            formData.append("name", "Blue Tier");
            formData.append("file", new File(["redheart"],Blue));
            formData.append(
              "attributes",
              JSON.stringify([
                {
                  trait_type: "name",
                  value: "Blue Tier",
                },
                {
                  trait_type: "discount",
                  value: "20",
                },
                {
                  trait_type: "start_date",
                  value: todayDate,
                },
                {
                  trait_type: "exp_date",
                  value: expDate,
                },
              ])
            );
          } else {
            var todayDate = new Date().toISOString().slice(0, 10);
            var expDate = new Date(new Date().setMonth(new Date().getMonth() + 9))
              .toISOString()
              .slice(0, 10);
            formData.append("name", "Red Tier");
            formData.append("file", new File(["redheart"],Red));
            formData.append(
              "attributes",
              JSON.stringify([
                {
                  trait_type: "name",
                  value: "Red Tier",
                },
                {
                  trait_type: "discount",
                  value: "40",
                },
                {
                  trait_type: "start_date",
                  value: todayDate,
                },
                {
                  trait_type: "exp_date",
                  value: expDate,
                },
              ])
            );
          }
          formData.append("network", "mainnet-beta");
          formData.append("wallet", walletId);
          formData.append("token_address", memToken);

          const xKey = "G6gjDRS12g4DkNBu";
          const endPoint = "https://api.shyft.to/sol/v1/";
          const privKey = "4g4r9LjKugEgm51i4brQSamMa7CyvcX6LibsNjHxVtBFp25FGXykccYVMSrhQLfS85Z6H6fWLqM12o6kmy5b9sC5";
          const pub_Key = "5dYcbon4BkjSZ8ivGiNqBYdFK97GJSAhV4wttx9f2pPB";

          let nftUrl = `${endPoint}nft/update_detach`;
          axios({
            // Endpoint to get NFTs
            url: nftUrl,
            method: "POST",
            headers: {
              "Content-Type": "multipart/form-data",
              "x-api-key": xKey,
            },
            data: formData,
          })
            // Handle the response from backend here
            .then(async (res) => {
              //console.log(res.data);

              console.log("NFTs: ");
              if (res.data.success === true) {
                const transaction = res.data.result.encoded_transaction;
                const ret_result = await signAndConfirm(
                  "devnet",
                  transaction,
                  privKey,
                  callback2,
                ); //flow from here goes to utility func
                console.log(ret_result);
              } else {
                console.log("failed");
              }
            })
            // Catch errors if any
            .catch((err) => {
              console.warn(err);
            });
        } else {
          //create to their wallet
          const publicKey = "5dYcbon4BkjSZ8ivGiNqBYdFK97GJSAhV4wttx9f2pPB"; //marketplace owner wallet
          var formData = new FormData();
          if (value === "green") {
            var todayDate = new Date().toISOString().slice(0, 10);
            var expDate = new Date(new Date().setMonth(new Date().getMonth() + 3))
              .toISOString()
              .slice(0, 10);
            formData.append("name", "COPE PASS");
            formData.append("image", new File(["redheart"],Green));
            // formData.append('file', fs.createReadStream(path.resolve(__dirname, Green)));

            formData.append(
              "attributes",
              JSON.stringify([
                {
                  trait_type: "name",
                  value: "COPE PASS",
                },
                {
                  trait_type: "level",
                  value: "1",
                },
                {
                  trait_type: "start_date",
                  value: todayDate,
                },
                {
                  trait_type: "exp_date",
                  value: expDate,
                },
              ])
            );
            formData.append(
              "service_charge",
              JSON.stringify(
                {
                  "receiver": publicKey,
                  "amount": 2500,
                  "token":"5GFF7e4eV4zzcbshdd8WdRFbknzTutKGBv2iwe1dWEgg" // Hii1Rz6MTJDsVuUz4UyinYHSW6J2Y6xt4nTPANDVrmkA  5EXGVkoDpygWkbJdvX8ANRK2CxnvK1BTuxG8uYXUb7G1
                },
              )
            );
          } else if (value === "blue") {
            var todayDate = new Date().toISOString().slice(0, 10);
            var expDate = new Date(new Date().setMonth(new Date().getMonth() + 6))
              .toISOString()
              .slice(0, 10);
            formData.append("name", "Blue Tier");
            formData.append("image", new File(["redheart"],Blue));
            formData.append(
              "attributes",
              JSON.stringify([
                {
                  trait_type: "name",
                  value: "Blue Tier",
                },
                {
                  trait_type: "discount",
                  value: "20",
                },
                {
                  trait_type: "start_date",
                  value: todayDate,
                },
                {
                  trait_type: "exp_date",
                  value: expDate,
                },
              ])
            );
            formData.append(
              "service_charge",
              JSON.stringify(
                {
                  "receiver": publicKey,
                  "amount": 1.0,
                },
              )
            );
          } else {
            var todayDate = new Date().toISOString().slice(0, 10);
            var expDate = new Date(new Date().setMonth(new Date().getMonth() + 9))
              .toISOString()
              .slice(0, 10);
            formData.append("name", "Red Tier");
            formData.append("image", new File(["redheart"],Red));
            formData.append(
              "attributes",
              JSON.stringify([
                {
                  trait_type: "name",
                  value: "Red Tier",
                },
                {
                  trait_type: "discount",
                  value: "40",
                },
                {
                  trait_type: "start_date",
                  value: todayDate,
                },
                {
                  trait_type: "exp_date",
                  value: expDate,
                },
              ])
            );
            formData.append(
              "service_charge",
              JSON.stringify(
                {
                  "receiver": publicKey,
                  "amount": 1.2,
                },
              )
            );
          }
          formData.append("network", "mainnet-beta");
          formData.append("creator_wallet", publicKey);
          formData.append("fee_payer", walletId);
          formData.append("symbol", "MEM");
          formData.append("max_supply", 0);
          formData.append("royalty", 1);
          formData.append("receiver", walletId);
          const xKey = "G6gjDRS12g4DkNBu";
          const privKey = "4g4r9LjKugEgm51i4brQSamMa7CyvcX6LibsNjHxVtBFp25FGXykccYVMSrhQLfS85Z6H6fWLqM12o6kmy5b9sC5";

          let nftUrl = `https://api.shyft.to/sol/v2/nft/create`;
          axios({
            // Endpoint to get NFTs
            url: nftUrl,
            method: "POST",
            headers: {
              "Content-Type": "multipart/form-data",
              "x-api-key": xKey,
            },
            data: formData,
          })
            // Handle the response from backend here
            .then(async (res) => {
              //console.log(res.data);
              // console.log("NFTs: ");
              if (res.data.success === true) {
                const transaction = res.data.result.encoded_transaction;
                console.log(transaction);
                const ret_result = await signAndConfirmBoth(
                  "mainnet-beta",
                  transaction,
                  privKey,
                  callback2,
                ); //flow from here goes to utility func

                console.log(ret_result);
              } else {
                console.log("failed");
              }
            })
            // Catch errors if any
            .catch((err) => {
              console.warn(err);
            });
        }

      }else{
        console.log('wallet not connected')
      }








    };













  return (
    <>

      <BgWrapper>
        <BottomLeftImgWrapper>
          <CompositeImage {...bottomLeftImage} />
        </BottomLeftImgWrapper>
        <TopRightImgWrapper>
          <CompositeImage {...topRightImage} />
        </TopRightImgWrapper>
      </BgWrapper>
      <TransparentFrame isDark={theme.isDark}>
        <Flex flexDirection="column" alignItems="center" justifyContent="center">

          <ColoredWordHeading textAlign="center" text={t('Buy Passes to unlock upgrades')} />
          <Text color="textSubtle">{t('Unlock everything on the site')}</Text>
          <Text mb="40px" color="textSubtle" />
          <Flex m="500 auto" flexDirection={['column', null, null, 'row']} maxWidth="1500px">
            <Flex
              flex="1"
              maxWidth={['275px', null, null, '100%']}
              mr={[null, null, null, '14px']}
              mb={['32px', null, null, '0']}
            >
              <IconCard {...PredictionCardData}>

                <PredictionCardContent />
                <Flex alignItems="center" justifyContent="center">

                    <Button  className="btn btn-light py-0 px-3 rounded-pill cfont"
                              onClick={() => renewMembership("green")} width="100%">
                      <Text bold color="invertedContrast">
                        {t('Purchase Pass')}
                      </Text>
                      <ArrowForwardIcon ml="4px" color="invertedContrast" />

                    </Button>




                </Flex>

              </IconCard>
            </Flex>
            <Flex
              flex="1"
              maxWidth={['275px', null, null, '100%']}
              mr={[null, null, null, '15px']}
              mb={['32px', null, null, '0']}
            >
              <IconCard {...PredictionCardData2}>
                <PredictionCardContent2 />
              </IconCard>
            </Flex>
            <Flex
              flex="1"
              maxWidth={['275px', null, null, '100%']}
              mr={[null, null, null, '-5px']}
              mb={['32px', null, null, '0']}
            >
              <IconCard {...PredictionCardData3}>
                <PredictionCardContent3 />
              </IconCard>
            </Flex>


          </Flex>
        </Flex>
      </TransparentFrame>
    </>
  )
}

export default WinSection
