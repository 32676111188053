// @ts-nocheck
// @ts-nocheck

import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ArrowForwardIcon, Button, Flex, Heading, Skeleton, Text, Modal } from '@pancakeswap/uikit'
import { NextLinkFromReactRouter } from 'components/NextLink'
import { useTranslation } from '@pancakeswap/localization'
import { formatLocalisedCompactNumber } from 'utils/formatBalance'
import useIntersectionObserver from 'hooks/useIntersectionObserver'
import { getTotalWon } from 'state/predictions/helpers'
import { useBNBBusdPrice, useCakeBusdPrice } from 'hooks/useBUSDPrice'
import { multiplyPriceByAmount } from 'utils/prices'
import useSWR from 'swr'
import { SLOW_INTERVAL } from 'config/constants'
import Balance from 'components/Balance'
import { useUserPredictionAcceptedRisk } from 'state/user/hooks'




const StyledBalance = styled(Text)`
  background: ${({ theme }) => theme.colors.gradients.gold};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`




const StyledLink = styled(NextLinkFromReactRouter)`
  width: 100%;
`

const PredictionCardHeader: React.FC<React.PropsWithChildren<{ preText: string; bnbWon: number }>> = ({
  preText,
  bnbWon,
}) => {
  const bnbBusdPrice = useBNBBusdPrice()
  const bnbWonInUsd = multiplyPriceByAmount(bnbBusdPrice, bnbWon)

  const localisedBnbUsdString = formatLocalisedCompactNumber(bnbWonInUsd)

  return (
    <Heading color="#280D5F" my="8px" scale="xl" bold>
      {preText}
      {localisedBnbUsdString}
    </Heading>
  )
}

const PredictionCardContent2 = () => {
  const { t } = useTranslation()
  const { observerRef, isIntersecting } = useIntersectionObserver()
  const [loadData, setLoadData] = useState(false)
  const [riskModal,setRiskModal]=useState(false)
  const bnbBusdPrice = useBNBBusdPrice()
  const cakePriceBusd = useCakeBusdPrice()

  const { data } = useSWR(loadData ? ['prediction', 'tokenWon'] : null, getTotalWon, {
    refreshInterval: SLOW_INTERVAL,
  })

  const bnbWonInUsd = multiplyPriceByAmount(bnbBusdPrice, data?.totalWonBNB || 0)
  const cakeWonInUsd = multiplyPriceByAmount(cakePriceBusd, data?.totalWonCAKE || 0)

  const localisedBnbUsdString = formatLocalisedCompactNumber(bnbWonInUsd + cakeWonInUsd)
  const bnbWonText = t('$%wonInUsd% in BNB + CAKE won so far', { wonInUsd: localisedBnbUsdString })
  const [pretext, wonSoFar] = bnbWonText.split(localisedBnbUsdString)
  bnbWonInUsd=1000000000000000000

  useEffect(() => {
    if (isIntersecting) {
      setLoadData(true)
    }



  }, [isIntersecting])

  /**
    if (riskModal===true){

      setTimeout(() => {
        riskModal=false
        console.log(riskModal)
      }, 3000);



    return(


      <Flex flexDirection="column" mt="48px">
        <RiskDisclaimer/>

        <Text color="#280D5F" bold fontSize="16px">
          {t('Level 2')}
        </Text>
        {bnbWonInUsd ? (
          <StyledBalance
            fontSize="40px"
            bold
            mb="20px"
          > 5000 COPE   </StyledBalance>
        ) : (
          <>
            <Skeleton width={230} height={40} my="8px" />
            <div ref={observerRef} />
          </>
        )}

        <Heading color="blue"> All of Level 1  + Unlock More Upgrades </Heading>
        <br/>
        <br/>
        <ul>

        <Text color="white" mb="30px" bold fontSize="16px"><li> Assess entire call performance history visually across time  </li></Text>
        <Text color="white" mb="145px" bold fontSize="16px"><li>Run Simulations across different timeframes on entire set of trading calls per trader </li></Text>


      </ul>


      <Flex alignItems="center" justifyContent="center">

          <Button width="100%" onClick={() =>setRiskModal(true)}>
            <Text bold color="invertedContrast">
              {t('Upgrade Now')}
            </Text>
            <ArrowForwardIcon ml="4px" color="invertedContrast" />
          </Button>

      </Flex>


      </Flex>




    )



  }
  **/

  return (
    <>
      <Flex flexDirection="column" mt="48px">

        <Text color="#280D5F" bold fontSize="16px">
          {t('Level 2')}
        </Text>
        {bnbWonInUsd ? (
          <StyledBalance
            fontSize="40px"
            bold
            mb="20px"
          > 5000 COPE   </StyledBalance>
        ) : (
          <>
            <Skeleton width={230} height={40} my="8px" />
            <div ref={observerRef} />
          </>
        )}

        <Heading color="blue"> All of Level 1  + Unlock More Upgrades </Heading>
        <br/>
        <br/>
        <ul>

        <Text color="white" mb="30px" bold fontSize="16px"><li> Assess entire call performance history visually across time  </li></Text>
        <Text color="white" mb="145px" bold fontSize="16px"><li>Run Simulations across different timeframes on entire set of trading calls per trader </li></Text>


      </ul>





      </Flex>
      <Flex alignItems="center" justifyContent="center">

          <Button width="100%" onClick={() =>setRiskModal(true)}>
            <Text bold color="invertedContrast">
              {t('Upgrade Now')}
            </Text>
            <ArrowForwardIcon ml="4px" color="invertedContrast" />
          </Button>

      </Flex>
    </>
  )
}

export default PredictionCardContent2
